import Container from "../components/base/container"
import PageLayout from "../components/base/page-layout"
import LinkGrid from "../components/common/link-grid"
import { fetchAllCitiesLinkForPurposeType, fetchAllLocationsForPurposeType } from "../services/NetworkRequests"
import { purposeLabelHash, typesHash } from "../utils/constants"
import { checkUA, recordPageView } from "../utils/utility"
import styles from "../components/citySlug/citySlug.module.scss"
import { useEffect } from "react"
const cities = require("../utils/cities.json")

const LinksPage = (props) => {
  const { links, h1, resolvedUrl } = props
  useEffect(() => {
    recordPageView(resolvedUrl)
  }, [resolvedUrl])

  return (
    <>
      <PageLayout headTitle="Lamudi.pk" bannerless={true}>
        <div className={styles.cityList}>
          <Container>
            <LinkGrid className="u-mb32" title={h1} headingVariant="h4" gridColumn="4" linkRowGap="8px" data={links} />
          </Container>
        </div>
      </PageLayout>
    </>
  )
}

export async function getServerSideProps(ctx) {
  let isMobile = checkUA(ctx.req.headers["user-agent"])
  let url = ctx.resolvedUrl
  let pathname = url.split("?")[0]
  pathname = pathname.replace(".html", "")

  pathname = pathname.replace(".html", "")

  // All Cities Page
  if (pathname.indexOf("/pakistan-") != -1) {
    pathname = pathname.replace("/", "")
    let splitted = pathname.split("-")
    let purpose = splitted[splitted.length - 1].replace("/", "")
    let type = splitted.slice(1, splitted.length - 1).join("-")
    let typeObj = typesHash[type]

    let res = await fetchAllCitiesLinkForPurposeType(purpose == "buy" ? 1 : 2, typeObj.id)
    let searchPages = res?.data?.links

    if (searchPages && searchPages.length > 0) {
      searchPages = searchPages.map((s) => {
        const { hierarchy = [], ...rest } = s
        return {
          title: s.link_text,
          count: s.ads_count,
          href: s.slug,
        }
      })

      return {
        props: {
          h1: `Find Best Location for ${typeObj.name} for ${purpose == "buy" ? "Buy" : "Rent"} in Pakistan`,
          isMobile,
          purpose_id: purpose == "buy" ? 1 : 2,
          links: searchPages,
          type: typeObj,
          resolvedUrl: ctx.resolvedUrl,
        },
      }
    }
  } else if (pathname.indexOf("all-locations") != -1) {
    let purpose = pathname.indexOf("rent-all-locations") == -1 ? 1 : 2
    let typeSlugs = Object.keys(typesHash)
    let type = typeSlugs.filter((t) => {
      return pathname.indexOf(`${t}-${purpose == 1 ? "buy" : "rent"}-all-locations`) != -1
    })[0]
    pathname = pathname.split("/").join("")
    let location = cities.filter((city) => {
      return city.name.split(" ").join("-").toLowerCase() == pathname.split(`-${type}`)[0]
    })[0]
    let res = await fetchAllLocationsForPurposeType(purpose, typesHash[type].id, location.externalID)
    let searchPages = res?.data?.links

    if (searchPages && searchPages.length > 0) {
      searchPages = searchPages.map((s) => {
        const { hierarchy = [], ...rest } = s
        return {
          title: s.locality_name,
          count: s.ads_count,
          href: s.slug,
        }
      })
      return {
        props: {
          h1: `Find Best Location for ${typesHash[type].name} for ${purpose == 1 ? "Sale" : "Rent"} in ${
            location.name
          }`,
          links: searchPages,
          resolvedUrl: ctx.resolvedUrl,
          isMobile,
        },
      }
    }
  }
  return {
    props: {
      isMobile,
      resolvedUrl: ctx.resolvedUrl,
    },
  }
}

export default LinksPage
